import PaginationMixin from "@/store/mixins/pagination";
import { RootState } from "@/store/types";
import { Module } from "vuex";
import {
  AwardProcedure,
  awardProcedureSorterActive,
  getDefaultSort,
} from "@/model/AwardProcedure";
import { Lock } from "@/model/Lock";
import {
  SET_ALL_AWARD_PROCEDURES,
  SET_AWARD_PROCEDURE_STATUS,
  SET_CURRENT_AWARD_PROCEDURE,
  SET_CURRENT_TAB_IN_LIST,
  SET_LOCK,
} from "./awardProcedure.mutations";
import { AwardProcedureState as AwardProcedureStoreState } from "./awardProcedure.types";
import {
  GET_ALL_AWARD_PROCEDURES,
  CREATE_AWARD_PROCEDURE,
  GET_AWARD_PROCEDURE_AS_CURRENT,
  CHECK_LOCK,
  DISMISS_NOTIFICATION,
  UPDATE_LOCK,
  EDIT_AWARD_PROCEDURE,
  DELETE_AWARD_PROCEDURE,
  COMPLETE_AWARD_PROCEDURE,
  MARK_AWARD_PROCEDURE_CANCELLED,
  EDIT_INTERNAL_DOCUMENTATION,
  RESET_AWARD_PROCEDURE_CURRENT,
  GET_AWARD_PROCEDURE_STATUS,
  CHANGE_BASIC_DATA,
  PUBLISH_AWARD_PROCEDURE,
  name,
} from "./awardProcedure.actions";
import Client from "@/utils/EvoClient";
import {
  CreateAwardProcedureDto,
  DismissAwardProcedureNotificationDto,
  EditAwardProcedureDto,
  DeleteAwardProcedureDto,
  CompleteAwardProcedureDto,
  MarkAwardProcedureCancelStatusDto,
  EditInternalDocumentationDto,
} from "@/utils/EvoClient/dto";

import router from "@/router";
import StoreNotificationService from "@/service/StoreNotificationService";
import { AwardProcedureState } from "@/model/AwardProcedureState";
import i18n from "@/i18n";
import { ROUTE_DASHBOARD } from "@/router/routes";

const store = {
  state: {
    all: [],
    current: null,
    status: null,
    tabState: AwardProcedureState.Active,
  } as AwardProcedureStoreState,
  actions: {
    async [CHANGE_BASIC_DATA](
      { dispatch },
      { title, shortDescription, fileNumber, awardProcedureId }
    ) {
      await Client.ChangeBasicData({
        title,
        shortDescription,
        fileNumber,
        awardProcedureId,
      });
      await dispatch(UPDATE_LOCK, { locked: true });
    },
    async [PUBLISH_AWARD_PROCEDURE]({ dispatch }, awardProcedureId: string) {
      await Client.PublishAwardProcedure({ awardProcedureId });
      await dispatch(UPDATE_LOCK, { locked: true } as Lock);
    },
    async [GET_ALL_AWARD_PROCEDURES]({
      dispatch,
      commit,
      state,
      getters,
      rootState,
    }) {
      if (rootState.isNavigating) {
        await dispatch(`${name}:updatePagination`, {
          sort: getDefaultSort(state.tabState),
        });
      }
      commit(
        SET_ALL_AWARD_PROCEDURES,
        await Client.GetAllAwardProcedures({
          filter: [{ field: "state", value: state.tabState }],
          pagination: getters[`${name}:pagination`],
        })
      );
    },
    async [CREATE_AWARD_PROCEDURE](_, args: CreateAwardProcedureDto) {
      return await Client.CreateAwardProcedure(args);
    },
    async [EDIT_AWARD_PROCEDURE]({ dispatch }, args: EditAwardProcedureDto) {
      await Client.EditAwardProcedure(args);
      await dispatch(GET_AWARD_PROCEDURE_AS_CURRENT, args.awardProcedureId);
      await dispatch(GET_AWARD_PROCEDURE_STATUS, args.awardProcedureId);
    },
    async [EDIT_INTERNAL_DOCUMENTATION](
      { dispatch },
      args: EditInternalDocumentationDto
    ) {
      await Client.EditInternalDocumentation(args);
      await dispatch(GET_AWARD_PROCEDURE_AS_CURRENT, args.awardProcedureId);
    },
    async [GET_AWARD_PROCEDURE_AS_CURRENT]({ commit, rootState }, id: string) {
      const awardProcedure = await Client.GetAwardProcedure(
        id,
        !rootState.specifications.initialized
      );
      commit(SET_CURRENT_AWARD_PROCEDURE, awardProcedure);
    },
    [RESET_AWARD_PROCEDURE_CURRENT]({ commit }) {
      commit(SET_CURRENT_AWARD_PROCEDURE, null);
    },
    async [GET_AWARD_PROCEDURE_STATUS](
      { commit, state },
      awardProcedureId: string
    ) {
      const awardProcedureStatus = await Client.GetAwardProcedureStatus(
        awardProcedureId || state.current!.id
      );
      commit(SET_AWARD_PROCEDURE_STATUS, awardProcedureStatus);
    },
    async [SET_CURRENT_TAB_IN_LIST]({ commit }, tabIndex: number) {
      commit(SET_CURRENT_TAB_IN_LIST, tabIndex);
    },
    async [DELETE_AWARD_PROCEDURE]({ state }, id: string) {
      await Client.DeleteAwardProcedure({
        awardProcedureId: id,
      } as DeleteAwardProcedureDto);
      const message = i18n.global.t(
        "award-procedures.list.notifications.award-procedure-deleted",
        {
          title: state.current!.title,
        }
      ) as string;
      await StoreNotificationService.setSuccessfulDeleteNotification(
        "dashboard",
        message
      );
      await router.push({ name: ROUTE_DASHBOARD });
    },
    async [COMPLETE_AWARD_PROCEDURE]({ dispatch }, id: string) {
      await Client.CompleteAwardProcedure({
        awardProcedureId: id,
      } as CompleteAwardProcedureDto);
      await dispatch(UPDATE_LOCK, { locked: true } as Lock); //Triggered Polling und ein Partial Refresh}
    },
    async [CHECK_LOCK](_, id: string) {
      return await Client.GetAwardProcedureLock(id);
    },
    async [UPDATE_LOCK]({ commit }, lock: Lock) {
      commit(SET_LOCK, lock);
    },
    async [DISMISS_NOTIFICATION](_, awardProcedureId: string) {
      const result = await Client.DismissAwardProcedureNotification({
        awardProcedureId,
      } as DismissAwardProcedureNotificationDto);
      return result;
    },
    async [MARK_AWARD_PROCEDURE_CANCELLED](
      _,
      dto: MarkAwardProcedureCancelStatusDto
    ) {
      await Client.MarkAwardProcedureCancelStatus(dto);
    },
  },
  mutations: {
    [SET_ALL_AWARD_PROCEDURES](state, verfahren: AwardProcedure[]) {
      state.all = verfahren;
    },
    [SET_CURRENT_AWARD_PROCEDURE](state, awardProcedure: AwardProcedure) {
      state.current = awardProcedure;
    },
    [SET_AWARD_PROCEDURE_STATUS](state, awardProcedureStatus: any) {
      state.status = awardProcedureStatus;
    },
    [SET_CURRENT_TAB_IN_LIST](state, tabState: AwardProcedureState) {
      state.tabState = tabState;
    },
    [SET_LOCK](state, lock: Lock) {
      if (state.current) {
        state.current = { ...state.current, ...lock };
      }
    },
  },
} as Module<AwardProcedureStoreState, RootState>;

export default PaginationMixin<AwardProcedureStoreState>(store, {
  actionWhichUpdatesAll: GET_ALL_AWARD_PROCEDURES,
  mutationWhichUpdatesAll: SET_ALL_AWARD_PROCEDURES,
  prefix: name,
  defaultSort: awardProcedureSorterActive[0].value,
  getTotalUnfilteredCount: Client.GetAllAwardProcedures,
});
