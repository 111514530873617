import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_evonum_radio_buttons = _resolveComponent("evonum-radio-buttons")!

  return (_openBlock(), _createBlock(_component_evonum_radio_buttons, {
    modelValue: _ctx.currentValue,
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change', $event))),
    options: _ctx.filterGroupOptions,
    showLegend: false,
    label: "Filter",
    vertical: "",
    mandatory: "",
    hint: _ctx.currentHint
  }, null, 8, ["modelValue", "options", "hint"]))
}