export const GET_ALL_AWARD_PROCEDURES = "Lädt alle Verfahren nach";
export const CREATE_AWARD_PROCEDURE = "Legt ein Verfahren an";
export const EDIT_AWARD_PROCEDURE = "Editiert ein Verfahren";
export const GET_AWARD_PROCEDURE_AS_CURRENT = "Lädt ein Verfahren in Current";
export const GET_AWARD_PROCEDURE_STATUS =
  "Lädt den Status der Veröffentlichung eines Verfahren";
export const CHECK_LOCK = "Prüfe ein Lock";
export const UPDATE_LOCK = "Setze das Lock";
export const DISMISS_NOTIFICATION = "Notification bestätigen";
export const DELETE_AWARD_PROCEDURE = "Löschen ein Verfahren";
export const COMPLETE_AWARD_PROCEDURE = "Schließt ein Verfahren ab";
export const MARK_AWARD_PROCEDURE_CANCELLED =
  "Markiert ein Verfahren als aufgehoben";
export const EDIT_INTERNAL_DOCUMENTATION =
  "Ermöglicht das hinzufügen einer internen Dokumentation";
export const RESET_AWARD_PROCEDURE_CURRENT =
  "Vergisst das Verfahren in Current";
export const CHANGE_BASIC_DATA = "Ändere die Grunddaten nach Start";
export const PUBLISH_AWARD_PROCEDURE =
  "Veröffentliche Verfahren und dessen Bestandteile";
export const name = "awardprocedures";
