import { User, Authority, Role, hasPermission } from "@/model/User";
import { UsersStoreState } from "./users.types";
import {
  SAVE_TOKEN_DATA,
  CLEAR_CURRENT_USER,
  SAVE_CURRENT_AUTHORITY,
  SAVE_CURRENT_USER,
  SAVE_AUTHORITY_ROLES,
  SAVE_UNASSIGNED_USERS,
  SAVE_CONTEXT_USERS,
  SAVE_CONTEXT,
} from "./users.mutations";
import {
  EDIT_AUTHORITY,
  EDIT_USER,
  GET_AUTHORITY_AS_CURRENT,
  GET_USER_AS_CURRENT,
  INVITE_USER,
  LOGIN,
  LOGOUT,
  REFRESH_TOKEN,
  REGISTER_WITH_CODE,
  REGISTER_WITH_DATA,
  GET_CURRENT_USERS_OF_CONTEXT,
  GET_ROLES_OF_CONTEXT_TYPE,
  GET_UNASSIGNED_USERS_OF_AUTHORITY,
  GRANT_USER_ROLE,
  LOAD_PERMISSIONS_VIEW,
  REMOVE_USER_FROM_CONTEXT,
  JOIN_ORGANISATION,
} from "./users.actions";
import { RootState } from "@/store/types";
import { Module } from "vuex";
import { KeycloakTokenParsed } from "keycloak-js";
import { REFRESH_INTERVAL } from "@/utils/keycloak";
import Client from "@/utils/EvoClient";
import {
  RegisterWithAuthorityDto,
  EditAuthorityDto,
  EditUserDto,
  InviteUserDto,
  RegisterWithInvitationDto,
  GrantUserRoleDto,
  RemoveUserFromContextDto,
  JoinOrganisationDto,
} from "@/utils/EvoClient/dto";
import { ROUTE_AUTHORITIES_SELECTION, ROUTE_DASHBOARD } from "@/router/routes";
import router from "@/router";
import {
  supportedFilter,
  supportedIncludes,
} from "@/utils/EvoClient/query/GetAllUsers";
import StoreNotificationService from "@/service/StoreNotificationService";
import i18n from "@/i18n";
import { NotFoundError, ServerValidationError } from "@/utils/errors";

const store = {
  state: {
    tokenData: undefined,
    user: null,
    authority: null,
    roles: {},
    unassignedUsers: [], // users that are not assigned to a role
    users: [],
    context: null,
  } as UsersStoreState,
  mutations: {
    [SAVE_TOKEN_DATA](state, data: KeycloakTokenParsed) {
      state.tokenData = {
        created: data.iat,
        id: data.sub!,
        userId: data.sub!,
        grant: [
          ...(data.realm_access?.roles || []),
          ...(data.resource_access?.account?.roles || []),
        ],
        username: data.preferred_username,
        validUntil: data.exp,
      };
    },
    [CLEAR_CURRENT_USER](state) {
      state.tokenData = undefined;
    },
    [SAVE_CURRENT_USER](state, data: User) {
      state.user = data;
    },
    [SAVE_CURRENT_AUTHORITY](state, authority: Authority) {
      state.authority = authority;
    },
    [SAVE_UNASSIGNED_USERS](state, users: User[]) {
      state.unassignedUsers = users;
    },
    [SAVE_AUTHORITY_ROLES](state, roles: Record<string, Role>) {
      state.roles = roles;
    },
    [SAVE_CONTEXT_USERS](state, users: User[]) {
      state.users = users;
    },
    [SAVE_CONTEXT](state, context: any) {
      state.context = context;
    },
  },
  actions: {
    async [JOIN_ORGANISATION]({ dispatch }, args: JoinOrganisationDto) {
      await Client.JoinOrganisation(args);
      await router.push({ name: ROUTE_AUTHORITIES_SELECTION });
    },
    async [REMOVE_USER_FROM_CONTEXT](
      { dispatch, rootState, state },
      { dto }: { dto: RemoveUserFromContextDto }
    ) {
      await Client.RemoveUserFromContext(dto);
      if (isRemovingHimself(dto, state.user!)) {
        const message = i18n.global.t(
          "award-procedures.list.notifications.removed-from-team",
          {
            title: rootState.awardProcedures.current!.title,
          }
        ) as string;
        await StoreNotificationService.setSuccessfulDeleteNotification(
          "dashboard",
          message
        );
        await router.push({ name: ROUTE_DASHBOARD });
      } else {
        await dispatch(LOAD_PERMISSIONS_VIEW);
      }
    },
    async [GRANT_USER_ROLE]({ dispatch }, { dto }: { dto: GrantUserRoleDto }) {
      await Client.GrantUserRole(dto);
      await dispatch(LOAD_PERMISSIONS_VIEW);
    },
    async [LOGIN]({ dispatch, state, commit }, data: KeycloakTokenParsed) {
      commit(SAVE_TOKEN_DATA, data);
      try {
        //get authority paramter, if present
        const currentURL = new URL(window.location.href);
        const authorityId =
          currentURL.searchParams.get("authority") || undefined;
        await dispatch(GET_AUTHORITY_AS_CURRENT, { authorityId });
      } catch (e: unknown) {
        if (isUserNotFoundError(e)) {
          //nicht im UserService, d.h. hoffentlich am Registrieren
          commit(SAVE_CURRENT_USER, null);
        } else {
          throw e;
        }
      }
      setTimeout(() => dispatch(REFRESH_TOKEN), REFRESH_INTERVAL * 1000);
    },
    async [REFRESH_TOKEN]({ state, dispatch }) {
      if (state.tokenData) {
        window.keycloak.updateToken(300).then((refreshed) => {
          console.log(REFRESH_TOKEN + ": " + refreshed);
          setTimeout(() => dispatch(REFRESH_TOKEN), REFRESH_INTERVAL * 1000);
        });
      }
    },
    async [GET_CURRENT_USERS_OF_CONTEXT]({ commit }) {
      const users = await Client.GetAllUsers({
        filter: [supportedFilter["userRoles.context"]],
        includes: [supportedIncludes.userRoles.value],
      });
      commit(SAVE_CONTEXT_USERS, users);
    },
    async [GET_ROLES_OF_CONTEXT_TYPE](
      { commit },
      { contextType = "AWARD_PROCEDURE" } = {}
    ) {
      const result: any = {};
      (await Client.GetRolesOfContextType(contextType)).forEach((role: any) => {
        result[role.id] = role;
      });
      commit(SAVE_AUTHORITY_ROLES, result);
    },
    async [GET_UNASSIGNED_USERS_OF_AUTHORITY]({ commit }) {
      const users = await Client.GetAllUsers({
        filter: [
          supportedFilter.authorities,
          supportedFilter["not.userRoles.context"],
        ],
      });
      commit(SAVE_UNASSIGNED_USERS, users);
    },
    async [LOAD_PERMISSIONS_VIEW]({ dispatch, commit, state, rootState }) {
      const [, , , _context] = await Promise.allSettled([
        dispatch(GET_ROLES_OF_CONTEXT_TYPE),
        dispatch(GET_UNASSIGNED_USERS_OF_AUTHORITY),
        dispatch(GET_CURRENT_USERS_OF_CONTEXT),
        Client.GetContext(rootState.awardProcedures.current!.id),
      ]);
      const context = _context.status === "fulfilled" ? _context.value : null;

      //temporär, bis die API angepasst ist
      if (context) {
        const currenUserCanGrantUserRole = hasPermission(
          state.user!,
          state.roles,
          "grantUserRole"
        );
        const currenUserCanRemoveUserFromContext = hasPermission(
          state.user!,
          state.roles,
          "removeUserFromContext"
        );
        const noPermissionConstraint = [
          {
            key: "NoPermissions",
            title: "Sie haben nicht die erforderliche Berechtigung",
          },
        ];
        context.actions = {
          grantUserRole: {
            status:
              currenUserCanGrantUserRole && state.unassignedUsers.length > 0
                ? "ALLOWED"
                : "NOT_ALLOWED",
            constraints: currenUserCanGrantUserRole
              ? []
              : noPermissionConstraint,
            allowedValues: [],
          },
          removeUserFromContext: {
            status:
              currenUserCanRemoveUserFromContext && state.users.length > 1
                ? "ALLOWED"
                : "NOT_ALLOWED",
            constraints: currenUserCanRemoveUserFromContext
              ? []
              : noPermissionConstraint,
            allowedValues: [],
          },
        };
      }
      commit(SAVE_CONTEXT, context);
    },
    async [LOGOUT]({ commit }) {
      commit(CLEAR_CURRENT_USER);
      window.keycloak.logout({ redirectUri: location.origin + "/" });
    },
    async [REGISTER_WITH_DATA]({ dispatch }, dto: RegisterWithAuthorityDto) {
      const id = await Client.RegisterWithAuthority(dto);
      if (id) {
        await dispatch(GET_AUTHORITY_AS_CURRENT, { mostRecent: true });
        await router.push({ name: ROUTE_DASHBOARD });
      }
    },
    async [REGISTER_WITH_CODE]({ dispatch }, dto: RegisterWithInvitationDto) {
      const id = await Client.RegisterWithInvitation(dto);
      if (id) {
        await dispatch(GET_AUTHORITY_AS_CURRENT, { mostRecent: true });
        await router.push({ name: ROUTE_DASHBOARD });
      }
    },
    async [EDIT_USER]({ dispatch }, dto: EditUserDto) {
      await Client.EditUser(dto);
      await dispatch(GET_USER_AS_CURRENT);
      await router.push({ name: ROUTE_DASHBOARD });
    },
    async [EDIT_AUTHORITY]({ dispatch }, dto: EditAuthorityDto) {
      await Client.EditAuthority(dto);
      await dispatch(GET_AUTHORITY_AS_CURRENT, {
        authorityId: dto.authorityId,
      });
      await router.push({ name: ROUTE_DASHBOARD });
    },
    async [INVITE_USER]({ dispatch }, dto: InviteUserDto) {
      await Client.InviteUser(dto);
      await dispatch(GET_AUTHORITY_AS_CURRENT, { authorityId: dto.id });
    },
    async [GET_USER_AS_CURRENT]({ commit, rootState }) {
      const user = await Client.GetUser({
        contextId: rootState.awardProcedures.current?.id,
      });
      commit(SAVE_CURRENT_USER, user);
    },
    async [GET_AUTHORITY_AS_CURRENT](
      { state, dispatch, commit },
      { authorityId, mostRecent } = {}
    ) {
      await dispatch(GET_USER_AS_CURRENT);
      if (state.user?.authorities && state.user?.authorities.length > 1) {
        let authority = null;
        if (authorityId) {
          authority = findAuthorityInUser(authority, state, authorityId);
        } else if (mostRecent) {
          authority = state.user.authorities[state.user.authorities.length - 1];
        }
        commit(SAVE_CURRENT_AUTHORITY, authority);
      } else if (
        state.user?.authorities &&
        state.user?.authorities.length === 1
      ) {
        let authority = state.user.authorities[0];
        if (authorityId) {
          authority = findAuthorityInUser(authority, state, authorityId);
        }
        commit(SAVE_CURRENT_AUTHORITY, authority);
      } else {
        //User hat noch keine, d.h. ist neu oder Portal
        commit(SAVE_CURRENT_AUTHORITY, null);
      }
    },
  },
} as Module<UsersStoreState, RootState>;

export default store;

function findAuthorityInUser(
  authority: any,
  state: UsersStoreState,
  authorityId: any
) {
  authority = state.user!.authorities.find((a) => a.id === authorityId)!;
  if (!authority) {
    throw new NotFoundError({
      title: i18n.global.t("common.errors.codes.AuthorityDoesNotExist"),
    });
  }
  return authority;
}

function isRemovingHimself(
  dto: RemoveUserFromContextDto,
  user: User | undefined
) {
  return dto.userId === user?.id;
}

function isUserNotFoundError(e: any): e is ServerValidationError {
  return (
    "errors" in e &&
    Array.isArray(e.errors) &&
    e.errors.length > 0 &&
    e.errors[0].code === "UserNotFound"
  );
}
