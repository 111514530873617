
import { defineComponent, PropType } from "vue";

import { Sorter } from "@/model/Sorter";
import PartialCardListFilters from "@/components/partials/partial-card-list-filters.vue";
import PartialCardListFiltersFilter from "@/components/partials/partial-card-list-filters-filter.vue";
import { DynamicFilterValue } from "@/model/FilterGroup";

/**
 * Eine bereits in einer [base-card](#basecard) gewrappte Variante der Pagination Liste.
 * Sollte in den meisten Fällen die richtige Variante im Vergleich zur eigentlichen
 * Pagination Liste sein.
 */

export default defineComponent({
  name: "base-card-list_wrapped-pagination",
  props: {
    /**
     * Ein Array an Sortern für die Pagination
     */
    sorter: {
      type: Array as PropType<Sorter>,
    },
    /**
     * Der Pagination Prefix
     */
    prefixName: {
      type: String,
    },
    /**
     * Sind Items in der Liste
     */
    hasItems: {
      type: Boolean,
    },
    /**
     * Wieviele Items sind in der Liste
     */
    numberOfItems: {
      type: Number,
    },
    /**
     * Überschrift der Liste
     */
    heading: {
      type: String,
    },
    /**
     * Der Aria Label der Liste
     */
    listLabel: {
      type: String,
    },
    /**
     * Parameter für die Pagination, falls nötig
     */
    listArgs: {},
    /**
     * Die Überschrift der Empty Card
     */
    listEmptyHeading: {
      type: String,
    },
    /**
     * Tag des Titels, der angezeigt wird, wenn keine Daten vorhanden sind.
     */
    listEmptyHeadingTag: {
      type: String,
    },
    /**
     * Der Text der Empty Card
     */
    listEmptyText: {
      type: String,
    },
    /**
     * Ein Bild für die Empty Liste
     */
    listEmptyImage: {
      type: String,
    },
    listEmptyImageAspectHeight: {
      type: Number,
    },
    listEmptyImageAspectWidth: {
      type: Number,
    },
    /**
     * Soll die Leere Karte nicht transparent sein?
     */
    noUseTransparentOnEmpty: {
      default: false,
      type: Boolean,
    },
    filterGroups: {
      type: Array as PropType<Array<any>>,
      default: () => [],
    },
    filterDisabled: {
      type: Object as PropType<Record<string, boolean>>,
      default: () => ({}),
    },
    filteredEmptyText: {
      type: String,
    },
    filteredEmptyHeading: {
      type: String,
    },
    /**
     * Zusätzliche Arguments für Filter.
     * Wird in SetFilter Methode verwendet
     * Key ist das Field des Filters
     * z.B. bei filter["isQualified"] ist es "isQualified"
     */
    filterArgs: {
      type: Object as PropType<{ [key: string]: DynamicFilterValue }>,
    },
    counter: {
      type: String,
    },
    updateAction: {
      type: String,
    },
    noFilter: {
      type: Boolean,
    },
  },
  components: {
    PartialCardListFilters,
    PartialCardListFiltersFilter,
  },
  data() {
    return {
      c_filterMap: this.$store.getters[`${this.prefixName}:filterMap`],
      c_filtering: !!this.$store.getters[`${this.prefixName}:isFilterSet`],
      c_activeFilterGroups:
        this.$store.getters[`${this.prefixName}:activeFilterGroups`],
    };
  },
  methods: {
    updateList(): Promise<void> {
      //Führt einen Backend-Call mit der registrierten Update-Action aus (z.B. UPDATE_PARTICIPANTS)
      //Aktualisiert die Listenanzeige
      return (this.$refs["list"] as any).updateList();
    },
    refreshFilter(): void {
      this.$emit("refresh-cache");
      this.c_filterMap = this.$store.getters[`${this.prefixName}:filterMap`];
      this.c_filtering =
        !!this.$store.getters[`${this.prefixName}:isFilterSet`];
      this.c_activeFilterGroups =
        this.$store.getters[`${this.prefixName}:activeFilterGroups`];
    },
    refreshCache(): void {
      //Aktualisiert die Listenanzeige gegen den Store, ohne die Action auszulösen (z.B. wenn die Action bereits woanders ausgelöst wurde)
      return (this.$refs["list"] as any).refreshCache();
    },
  },
  computed: {
    filtering(): boolean {
      return this.c_filtering;
    },
    noToolbar(): boolean {
      return !this.filtering && !this.hasItems;
    },
    showTransparentEmptyCard(): boolean {
      return !this.noUseTransparentOnEmpty && this.noToolbar;
    },
  },
});
