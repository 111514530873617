import { Role } from "@/model/User";
import UrlBuilder from "@/utils/EvoClient/urlBuilder";
import { AwardingClient } from "@/utils/EvoClient";
import deserializer from "@/utils/EvoClient/deserializer";

const ENDPOINT = "users/roles";

export default async function getRoles(
  contextType = "AWARD_PROCEDURE"
): Promise<Role[]> {
  const result = await AwardingClient.get(
    UrlBuilder.fromBaseUrl(ENDPOINT)
      .addFilter("contextType", contextType)
      .toString()
  );
  return deserializer.deserialize(result.data);
}
