
import { defineComponent } from "vue";
import i18n from "@/i18n";
import LoadService from "@/service/LoadingService";
import { Filterable, Paginationable } from "@/store/types";
import PartialCardListSelectSmall from "@/components/partials/partial-card-list-select-small.vue";
import PartialCardListPagination from "@/components/partials/partial-card-list-pagination.vue";
import { PartialCardListItemWrapper } from "@evo/evonum";
import { PropType } from "vue";
import PartialCardListSort from "../partials/partial-card-list-sort.vue";
import { PartialCardListEmptyText } from "@evo/evonum";
import { Sorter } from "@/model/Sorter";

const ITEMS_PRO_SEITE_OPTIONS = [
  { title: "25", value: 25 },
  { title: "10", value: 10 },
  { title: " 5", value: 5 },
];

/**
 * Eine Liste zum Einsatz in einer [base-card](#!/base-card).
 * Sie nutzt das Pagination-Mixin des Stores, um die Daten paginiert anzuzeigen.
 * Alle notwendigen Elemente (Sortierung, Paginierung) sind bereits integriert.
 * Für den Fall, dass die Liste keine Elemente hat, wird eine besondere Anzeige
 * mit entsprechenden Hinweisen eingeblendet.
 * Alle Elemente der Liste sollten über [evonum-card-list-item](#!/evonum-card-list-item)
 * hinzugefügt werden.
 */
export default defineComponent({
  name: "base-card-list_pagination",
  components: {
    PartialCardListSelectSmall,
    PartialCardListPagination,
    PartialCardListItemWrapper,
    PartialCardListSort,
    PartialCardListEmptyText,
  },
  props: {
    /**
     * Bild, das angezeigt wird, wenn keine Daten vorhanden sind.
     */
    emptyListImage: String,
    /**
     * Aspect Width des Bildes, sonst quadratisch.
     */
    emptyListImageAspectWidth: Number,
    /**
     * Aspect Height des Bildes, sonst quadratisch.
     */
    emptyListImageAspectHeight: Number,
    /**
     * Titel, der angezeigt wird, wenn keine Daten vorhanden sind.
     */
    emptyListHeading: String,
    /**
     * Tag des Titels, der angezeigt wird, wenn keine Daten vorhanden sind.
     */
    emptyListHeadingTag: {
      type: String,
    },
    /**
     * Nachricht, die angezeigt wird, wenn keine Daten vorhanden sind.
     */
    emptyListText: String,
    /**
     * Präfix, über welches auf die Pagination und Daten aus dem Store zugegriffen werden soll.
     */
    prefix: String,
    /**
     * Sortierungsbeschriftung und -Wert.
     */
    sorter: {
      type: Array as PropType<Sorter>,
      required: true,
    },
    /**
     * Argumente, welche die Update-Routine benötigt, damit Daten nachgeladen werden können.
     */
    args: {
      type: Object as PropType<any>,
      default: null,
    },
    /**
     * Property aus den Daten, welcher als (Aria-)Label für die Elemente verwendet wird.
     */
    titleProperty: {
      type: String,
      default: "title",
    },
    /**
     * Aria-Label für die gesamte Liste.
     */
    listAriaLabel: {
      type: String,
      default: i18n.global.t("aria.list.label"),
    },
    /**
     * Text der angezeigt wird, wenn die Liste leer ist und Filter gesetzt sind
     */
    filteredEmptyText: {
      type: String,
    },
    /**
     * Headline der angezeigt wird, wenn die Liste leer ist und Filter gesetzt sind
     */
    filteredEmptyHeading: {
      type: String,
    },
    /**
     * Action, welche anstelle der default Pagination-Laden Action ausgeführt werden soll
     * Nützlich für Zusätzliches Laden von Dingen.
     * Bekommt ebenfalls die Args übergeben.
     */
    updateAction: {
      type: String,
    },
  },
  inject: {
    enhanceItem: {
      default: () => (item: any) => item,
    },
  },
  data(this: any) {
    return {
      perpageOptions: ITEMS_PRO_SEITE_OPTIONS,
      loading: false,
      c_items: this.$store.getters[`${this.prefix}:all`]?.map((x: any) =>
        this.enhanceItem(x)
      ),
      c_sorter: this.sorter,
      c_page: this.$store.getters[`${this.prefix}:pagination`].page.number + 1,
      c_perpage: this.$store.getters[`${this.prefix}:pagination`].page.size,
      c_total: this.$store.getters[`${this.prefix}:pagination`].total,
      c_hasFilters: this.$store.getters[`${this.prefix}:filter`]?.length > 0,
      c_filterSet: !!this.$store.getters[`${this.prefix}:isFilterSet`],
      c_sort: this.$store.getters[`${this.prefix}:pagination`].sort,
      numberOfPages:
        this.$store.getters[`${this.prefix}:pagination`].totalPages,
    };
  },
  created() {
    this.$emit(
      "has-active-filter",
      this.$store.getters[`${this.prefix}:filter`]?.length > 0
    );
  },
  methods: {
    async updateList() {
      LoadService.reset();
      this.loading = true;
      LoadService.startProgressbar();
      LoadService.addLoadAction(
        this.updateAction || `${this.prefix}:update`,
        this.args || undefined
      );
      try {
        await LoadService.waitForAll();
        this.$emit("refresh-cache");
        this.refreshCache();
      } finally {
        LoadService.stopProgressbar();
        this.loading = false;
      }
    },
    async updatePagination(pagination: Paginationable["pagination"]) {
      await this.$store.dispatch(`${this.prefix}:updatePagination`, pagination);
      this.updateList();
    },
    updateSort(sort: string) {
      this.updatePagination({
        sort,
      } as Paginationable["pagination"]);
    },
    refreshCache() {
      this.$emit("has-active-filter", this.filter.length > 0);
      this.c_page = this.pagination.page.number + 1;
      this.c_sort = this.pagination.sort;
      this.c_sorter = this.sorter;
      this.c_perpage = this.pagination.page.size;
      this.c_total = this.pagination.total;
      this.numberOfPages = this.pagination.totalPages;
      this.c_hasFilters = this.filter.length > 0;
      this.c_filterSet = !!this.$store.getters[`${this.prefix}:isFilterSet`];
    },
    calculatePageOverride(size: number, oldSize: number): number {
      return Math.max(
        0,
        Math.floor((this.pagination.page.number * oldSize) / size)
      );
    },
  },

  computed: {
    filtering(): boolean {
      return this.c_filterSet;
    },
    currentSorter(): (a: any, b: any) => number {
      return this.$utils.noop;
    },
    hasFilterSet(): boolean {
      return this.c_hasFilters;
    },
    pagination(): Paginationable["pagination"] {
      return this.$store.getters[`${this.prefix}:pagination`];
    },
    filter(): Filterable["filter"][keyof Filterable["filter"]] {
      return this.$store.getters[`${this.prefix}:filter`] || [];
    },
    itemsInPage(this: any): Array<any> {
      return this.$store.getters[`${this.prefix}:all`]?.map((x: any) =>
        this.enhanceItem(x)
      );
    },
    itemTotalCount(): number {
      return this.pagination.total;
    },
    showNavigation(): boolean {
      return this.itemTotalCount > 0 || this.filtering;
    },
    lowestPosInSetShown(): number {
      return (this.page - 1) * this.perpage + 1;
    },
    highestPosInSetShown(): number {
      return Math.max(
        (this.page - 1) * this.perpage + 1,
        this.itemTotalCount - 1
      );
    },
    page: {
      get: function (this: any): number {
        return this.c_page;
      },
      set: function (this: any, val: number): void {
        this.updatePagination({
          page: {
            number: Math.max(0, val - 1),
          },
        } as Paginationable["pagination"]);
      },
    } as unknown as () => number,
    perpage: {
      get: function (this: any): number {
        return this.c_perpage;
      },
      set: function (
        this: {
          pagination: Paginationable["pagination"];
          updatePagination: any;
          calculatePageOverride: any;
          perpage: number;
        },
        val: number
      ): void {
        let pageOverride = this.calculatePageOverride(val, this.perpage);

        this.updatePagination({
          page: {
            number: Number.isNaN(pageOverride)
              ? this.pagination.page.number
              : pageOverride,
            size: val,
          },
        } as Paginationable["pagination"]);
      },
    } as unknown as () => number,
  },
  watch: {
    page(): void {
      this.$utils.announcePolite(
        this.$t("aria.list.pageChangedAnnouncement", {
          page: this.page,
          totalPages: this.numberOfPages,
        }) as string
      );
    },
    loading(value: boolean): void {
      this.$emit("loading", value);
    },
    "pagination.totalPages": {
      handler: function (newPages: number): void {
        if (!this.$store.state.isNavigating) {
          if (this.page > newPages) {
            this.page = newPages;
          }
          if (this.numberOfPages != newPages) {
            this.numberOfPages = newPages;
          }
        }
      },
    },
    itemsInPage(val): void {
      if (!this.$store.state.isNavigating) {
        this.c_items = val;
      }
      if (this.itemTotalCount == 0) {
        this.$utils.announcePolite(
          this.$t("aria.list.noEntriesAnnouncement") as string
        );
      } else {
        this.$utils.announcePolite(
          this.$t("aria.list.entriesShownAnnouncement", {
            total: this.itemTotalCount,
            from: this.lowestPosInSetShown,
            to: this.highestPosInSetShown,
          }) as string
        );
      }
    },
  },
});
