import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  style: {"border-top":"thin solid var(--evo-border-color)"},
  "data-cy": "base-card-list-pagination-filtered-empty"
}
const _hoisted_2 = { style: {"width":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_partial_card_list_sort = _resolveComponent("partial-card-list-sort")!
  const _component_evonum_col = _resolveComponent("evonum-col")!
  const _component_partial_card_list_item_wrapper = _resolveComponent("partial-card-list-item-wrapper")!
  const _component_partial_card_list_empty_text = _resolveComponent("partial-card-list-empty-text")!
  const _component_partial_card_list_pagination = _resolveComponent("partial-card-list-pagination")!
  const _component_partial_card_list_select_small = _resolveComponent("partial-card-list-select-small")!
  const _component_evonum_row = _resolveComponent("evonum-row")!
  const _component_evonum_card_list = _resolveComponent("evonum-card-list")!

  return (_openBlock(), _createBlock(_component_evonum_card_list, {
    hasItems: _ctx.itemTotalCount > 0 || _ctx.filtering,
    listAriaLabel: _ctx.listAriaLabel,
    emptyListHeading: _ctx.emptyListHeading,
    emptyListText: _ctx.emptyListText,
    emptyListHeadingTag: _ctx.emptyListHeadingTag,
    emptyListImage: _ctx.emptyListImage,
    "has-header": "",
    "has-footer": ""
  }, {
    header: _withCtx(() => [
      (_ctx.showNavigation)
        ? (_openBlock(), _createBlock(_component_evonum_col, {
            key: 0,
            cols: 12,
            class: "d-flex justify-end px-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_partial_card_list_sort, {
                "model-value": _ctx.c_sort,
                "onUpdate:modelValue": _ctx.updateSort,
                items: _ctx.c_sorter
              }, null, 8, ["model-value", "onUpdate:modelValue", "items"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "list", {}, () => [
        (_ctx.itemTotalCount > 0)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.c_items, (item, index) => {
              return (_openBlock(), _createBlock(_component_partial_card_list_item_wrapper, {
                key: 'row' + index,
                ariaLabel: item[_ctx.titleProperty],
                ariaSetsize: _ctx.itemTotalCount,
                ariaPosinset: (_ctx.page - 1) * _ctx.perpage + index + 1
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", { item: item })
                ]),
                _: 2
              }, 1032, ["ariaLabel", "ariaSetsize", "ariaPosinset"]))
            }), 128))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "noFilteredItems", {}, () => [
                _createVNode(_component_partial_card_list_empty_text, {
                  role: "listitem",
                  heading: 
                  _ctx.hasFilterSet ? _ctx.filteredEmptyHeading : _ctx.emptyListHeading
                ,
                  text: _ctx.hasFilterSet ? _ctx.filteredEmptyText : _ctx.emptyListText
                }, null, 8, ["heading", "text"])
              ])
            ]))
      ])
    ]),
    footer: _withCtx(() => [
      (_ctx.showNavigation)
        ? (_openBlock(), _createBlock(_component_evonum_row, {
            key: 0,
            class: "flex-grow-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_evonum_col, {
                class: "d-flex align-center v-col-12 v-col-md-8 pl-0 pb-0 justify-center justify-md-start",
                style: {"min-height":"100%"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_partial_card_list_pagination, {
                      modelValue: _ctx.page,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
                      length: _ctx.numberOfPages || 1,
                      disabled: _ctx.loading,
                      "total-visible": 7,
                      "data-cy": "base-card-list-pagination-pagination"
                    }, null, 8, ["modelValue", "length", "disabled"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_evonum_col, {
                class: "d-flex align-center v-col-12 v-col-md-4 pr-0 pb-0 pl-0 pl-md-2 justify-md-end justify-center",
                style: {"min-height":"100%","transform":"translateY(-8px)"},
                "data-cy": "base-card-list-pagination-dropdown"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_partial_card_list_select_small, {
                    items: _ctx.perpageOptions,
                    widthByText: "",
                    readonly: _ctx.loading,
                    label: _ctx.$t('common.list.rowsPerPage'),
                    class: "evonum-select-small--pages flex-shrink-0",
                    modelValue: _ctx.perpage,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.perpage) = $event))
                  }, null, 8, ["items", "readonly", "label", "modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["hasItems", "listAriaLabel", "emptyListHeading", "emptyListText", "emptyListHeadingTag", "emptyListImage"]))
}